.code-highlight {
  pre {
    margin-bottom: 4px;
    code {
      * {
        font-family: inherit !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 16px !important;
      }
    }
  }
}
