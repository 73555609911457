.image-loop-wrapper {
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 50%;
  overflow: hidden;

  .image-loop-img {
    width: 52px;
    min-height: 52px;
    border-radius: 50%;
    height: 52px;
    margin-bottom: 2px;
    object-fit: cover;
    animation: image-loop-anim 2600ms ease-in-out infinite;
  }
}

.grid-area-1 {
  grid-area: 1/1;
}

.logo-animation {
  width: 54px;
  height: 54px;
  background-size: cover;
  animation: slide-animation 0.8s infinite;
}

.hotspot-target {
  .inner-circle {
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    animation-name: innerCircleAnimation;
  }
  .outer-circle {
    opacity: 0;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    animation-name: outerCircleAnimation;
    animation-delay: 1s;
  }
}
.ease-bouncy {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.highlight-input {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.15);
  input {
    animation: shake 0.2s ease-in-out 0s 2;
  }
}

@keyframes slide-animation {
  0%,
  12.5% {
    background-image: url(/assets/images/logo-animation/1.png);
  }
  12.5%,
  25% {
    background-image: url(/assets/images/logo-animation/2.png);
  }
  25%,
  37.5% {
    background-image: url(/assets/images/logo-animation/3.png);
  }
  37.5%,
  50% {
    background-image: url(/assets/images/logo-animation/4.png);
  }
  50%,
  62.5% {
    background-image: url(/assets/images/logo-animation/5.png);
  }
  62.5%,
  75% {
    background-image: url(/assets/images/logo-animation/6.png);
  }
  75%,
  87.5% {
    background-image: url(/assets/images/logo-animation/7.png);
  }
  87.5%,
  100% {
    background-image: url(/assets/images/logo-animation/4.png);
  }
}

@keyframes image-loop-anim {
  0%,
  20% {
    transform: translatey(6px);
  }
  25%,
  45% {
    transform: translatey(-48px);
  }
  50%,
  70% {
    transform: translatey(-102px);
  }
  75%,
  95% {
    transform: translatey(-156px);
  }
  100% {
    transform: translatey(6px);
  }
}

@keyframes innerCircleAnimation {
  0% {
    transform: scaleX(1);
  }

  18% {
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes outerCircleAnimation {
  0% {
    transform: scaleX(1);
    opacity: 1;
  }

  100% {
    transform: scale3d(2.3, 2.3, 2.3);
    opacity: 0;
  }
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0rem;
  }
}
