.unstyled-button {
  width: max-content;
  height: max-content;
  box-shadow: none;
  padding: 0px;
}

.animated-button-span {
  position: absolute;
  z-index: 1;
  user-select: none;
  pointer-events: none;
  animation-name: button-pulse-animation;
  animation-duration: 800ms;
  animation-delay: 200ms;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  border-radius: 160px;
}

@keyframes button-pulse-animation {
  0% {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
  }
  40% {
    top: -15px;
    right: -15px;
    left: -15px;
    bottom: -15px;
    background: rgba(0, 0, 0, 0.02);
  }
  45% {
    top: -20px;
    right: -20px;
    left: -20px;
    bottom: -20px;
    background: rgba(0, 0, 0, 0);
  }
  100% {
    top: 5px;
    right: 5px;
    left: 5px;
    bottom: 5px;
    background: rgba(0, 0, 0, 0.1);
  }
}
