.Toastify {
  .Toastify__toast {
    margin-bottom: 15px !important;
  }
  .Toastify__toast-container {
    padding: 0px !important;
    width: 320px !important;
    overflow-y: auto;

    @media only screen and (min-width: 420px) {
      width: 360px !important;
    }
    &--top-center {
      top: 24px !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
      max-height: calc(100% - 24px) !important;
    }
    &--top-right {
      right: 1em !important;
      top: 1em !important;
      left: auto !important;
      max-height: calc(100% - 1em) !important;
    }
    .Toastify__toast {
      background: inherit !important;
      color: inherit !important;
      min-height: auto !important;
      padding: 0px !important;
      box-shadow: none !important;
      overflow: visible !important;
      font-family: inherit !important;
      .Toastify__toast-body {
        padding: 0px !important;
      }
      .Toastify__close-button {
        position: absolute !important;
        right: 12px !important;
        top: 12px !important;
      }
    }
  }
}
