[data-inbox-fullscreen='open'] {
  position: fixed;
  background: white;
  left: 0;
  top: 0px;
  bottom: 0px;
  right: 0px;
  padding: 0px 16px;

  .inbox-grid {
    height: 100vh;
    .inbox-sidebar {
      height: 100vh;
      .inbox-tab-select {
        margin-left: -16px;
        padding-left: 16px;
      }
    }
    .inbox-messenger {
      height: 100vh;
    }
    .inbox-right-bar {
      height: 100vh;
      .inbox-detail-title {
        margin-right: -16px;
        padding-right: 32px;
      }
    }
  }
}
