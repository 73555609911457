#message {
  code {
    background-color: var(--message-box-code-background);
    color: inherit;
  }
  img {
    margin: 0px;
  }
}

.detail-section-hidden {
  height: 0px !important;
  opacity: 0 !important;
  visibility: hidden !important;
  pointer-events: none !important;
  margin-bottom: 0px !important;
}

.prose {
  font-size: 14px;
  font-weight: 400;

  h1 {
    font-size: 1.38em;

    &:not(:last-child) {
      margin-top: 0;
      margin-bottom: 0.85em;
    }
  }
  h2 {
    font-size: 1.23em;

    &:not(:last-child) {
      margin-top: 0;
      margin-bottom: 0.58em;
    }
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  :where(code):not(:where([class~='not-prose'] *)):before {
    content: none;
  }
  :where(code):not(:where([class~='not-prose'] *)):after {
    content: none;
  }

  :where(figure):not(:where([class~='not-prose'] *)) {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  :where(code):not(:where([class~='not-prose'] *)) {
    background-color: var(--tw-prose-pre-code);
    padding: 0.2em 0.4em;
    border-radius: 4px;
    font-weight: 500;
  }

  :where(pre code):not(:where([class~='not-prose'] *)) {
    background-color: transparent;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-weight: inherit;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }

  :where(a):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
    color: inherit;
    font-weight: 600;
    text-underline-offset: 2px;
  }

  :where(strong),
  :where(ol > li)::marker {
    color: inherit;
  }

  .footnotes {
    :where(ol > li)::marker,
    ol > li,
    p {
      font-size: 12px;
      line-height: 16px;
    }

    p {
      font-style: italic;
    }
  }

  :where(thead):not(:where([class~='not-prose'], [class~='not-prose'] *)) {
    border-bottom-width: 1px;
    border-top-width: 1px;
    border-bottom-color: var(--tw-prose-th-borders);
    border-top-color: var(--tw-prose-th-borders);
  }
}

.conversation-list-radius {
  border-top-left-radius: var(--conversation-list-radius, 25px);
  border-top-right-radius: var(--conversation-list-radius, 25px);
}

.messenger-header {
  height: var(--header-height, 198px);
  min-height: var(--header-min-height, 198px);
  justify-content: var(--header-justify-content, space-between);
}

.messenger-header-agent-display {
  display: var(--header-agents-display, flex);
}

.messenger-header-close-position {
  position: var(--header-close-position, static);
  top: 10px;
  right: 15px;
}

.livechatai-messenger-grid {
  grid-template-rows: var(--wrapper-dynamic-grid-template-rows, 48px 1fr auto);
}

//

.message-last-element {
  position: relative;
}

.message-last-element-character {
  display: inline-block;
  position: relative;
}

.message-last-element-character::before {
  content: '';
  position: absolute;
  top: -16px;
  left: -35px;
  width: 35px;
  height: 20px;
  background-image: linear-gradient(
    90deg,
    rgba(209, 213, 219, 0) 0%,
    rgba(242, 243, 244, 1) 100%
  );
}
