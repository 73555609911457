/* Placeholder (at the top) */
.tiptap p.is-editor-empty:first-child::before {
  color: #999999;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.tiptap img {
  max-width: 60%;
}

.markdown-tiptap {
  img {
    max-width: 100%;
    border-radius: 6px;
    border: 1px solid transparent;

    &.ProseMirror-selectednode {
      outline: 2px solid #2188f3;
      outline-offset: 2px;
    }
  }
}

.tiptap {
  a:has(.node-image) {
    text-decoration: none;
  }
}
