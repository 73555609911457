.messenger-tooltip {
  background-color: black !important;
  color: white !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  padding: 6px 12px !important;
  z-index: 9999;
}
.messenger-tooltip-arrow {
  display: none !important;
}

.messenger-time-tooltip {
  background-color: rgb(148, 148, 148) !important;
  color: white !important;
  border-radius: 6px !important;
  font-weight: 350 !important;
  font-size: 10px !important;
  line-height: 14px !important;
  padding: 3px 6px !important;
  z-index: 0;
}
.messenger-time-tooltip-arrow {
  display: none !important;
}
