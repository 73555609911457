.chatbot-customize-accordion {
  border-radius: 8px;
  border: 1px solid #d1d5db;
  box-shadow: none;

  &[data-headlessui-state='open'] {
    border: none;
  }

  .accordion-item__button {
    padding: 24px 20px;
    background-color: white;
    box-shadow: none;
    filter: grayscale(1);
    transition: all ease-in-out 300ms;

    h6 {
      @apply text-gray-500 transition-colors;
    }
  }

  .accordion-open {
    border-radius: 8px;
    border: 1px solid #22d3ee;
    background-color: white;
    box-shadow: 0px 9px 20px 0px rgba(0, 0, 0, 0.15);

    .accordion-item__button {
      padding: 30px 20px;
      filter: none;
      border: none;

      h6 {
        @apply text-black;
      }
    }

    .accordion-item__panel {
      padding: 0px;
    }
  }
}
