/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.search-date-input-wrapper {
  position: relative;
  width: fit-content;

  .search-date-input {
    @apply transition-colors;

    padding: 7px 10px 7px 29px;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    border-radius: 6px;
    background: #fff;
    width: 208px;
    border: 1px solid var(--gray-400, #9ca3af);
    color: var(--black, #000);

    &[value=''],
    &:not([value]) {
      width: 70px !important;
      border: 1px solid #eaeaea !important;
      color: var(--gray-500, #6b7280) !important;
    }
  }

  .search-date-button {
    position: absolute;
    padding: 0px;
    width: 18px;
    height: 18px;
    left: 7px;
    top: 50%;
    transform: translateY(-50%);
    color: #9ca3af;
    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.webhook-url-input {
  .tiptap.ProseMirror {
    width: 100%;
    height: 20px;
    outline: none;
    white-space: nowrap;
    padding-right: 8px;

    br {
      display: none;
    }
  }
}
